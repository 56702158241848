// header.jsx
import React from "react";
import { ArrowDownBlock } from "./ArrowDownBlock";
import BitAnimation from "./BitAnimation";

export const Header = (props) => {
  return (
      <header id="header">
        <BitAnimation />
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a
                      href="#about"
                      className="btn btn-lg btn-block page-scroll"
                  >
                    <ArrowDownBlock></ArrowDownBlock>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};
