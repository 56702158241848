export const ArrowDownBlock = () => {
  return (
    <div className="md:h-12 flex items-start justify-center">
      <img
        src="img/arrows-down.svg"
        className="animate-bounce"
        width={48}
        alt="arrows-down"
      />
    </div>
  );
};
