import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros Servicios</h2>
          <p>
            En nuestra empresa ofrecemos una gama de servicios especializados diseñados para apoyar tanto a instituciones académicas como a empresas en la elaboración y evaluación de proyectos de investigación y consultoría. Nuestra experiencia abarca desde la asesoría en la redacción de propuestas hasta la evaluación crítica de proyectos existentes, asegurando que cumplan con los más altos estándares de calidad académica y aplicabilidad empresarial. Nos especializamos en adaptar cada proyecto a las necesidades específicas de nuestros clientes, utilizando herramientas avanzadas y metodologías probadas para garantizar resultados óptimos. En Bit Project, estamos comprometidos a transformar tu visión investigativa en realidad tangible y exitosa.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
