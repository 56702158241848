import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [formStatus, setFormStatus] = useState(""); // Nuevo estado para el mensaje de confirmación

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reemplazar con tus propios Service ID, Template ID y Public Key de tu cuenta de EmailJS
    emailjs
        .sendForm("service_xxjr1nf", "template_qr112sc", e.target, "oBm3_aaCuvRQ8RAza")
        .then(
            (result) => {
              console.log(result.text);
              setFormStatus("El mensaje se envió correctamente."); // Actualizar estado en caso de éxito
              clearState();
            },
            (error) => {
              console.log(error.text);
              setFormStatus("Ocurrió un error al enviar el mensaje."); // Actualizar estado en caso de error
            }
        );
  };

  return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contactanos</h2>
                  <p>
                    Por favor, llena el siguiente formulario para enviarnos un mensaje. Nos pondremos en contacto contigo lo antes posible.
                  </p>
                </div>
                <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Nombre"
                            required
                            onChange={handleChange}
                            value={name}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            required
                            onChange={handleChange}
                            value={email}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                  <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Mensaje"
                      required
                      onChange={handleChange}
                      value={message}
                  ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar
                  </button>
                </form>
                {formStatus && (
                    <div className="alert alert-info" style={{ marginTop: "20px" }}>
                      {formStatus}
                    </div>
                )}
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Información de contacto</h3>
              </div>
              <div className="contact-item">
                <a href="https://api.whatsapp.com/send?phone=542644454215&text=Hola%20Claudio%2C%20te%20contacto%20desde%20Bit%20Project.%20Quiero%20saber%20más%20información%20">
                <span>
                  <i className="fa fa-whatsapp"></i> WhatsApp (click para enviar)
                </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </a>
              </div>
              <div className="contact-item">
                <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.linkedin : "/"}>
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.whatsapp : "/"}>
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2024 design by{" "}
              <a href="https://www.entrypointsw.com" rel="nofollow">
                Entry Point
              </a>
            </p>
          </div>
        </div>
      </div>
  );
};
