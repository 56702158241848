import React from 'react';

const BitAnimation = () => {
    const generateRandomPosition = () => ({
        '--random-x-start': Math.random(),
        '--random-y-start': Math.random(),
        '--random-x-end': Math.random(),
        '--random-y-end': Math.random()
    });

    const bits = Array.from({ length: 100 }, (_, i) => i);

    return (
        <div className="bit-container">
            {bits.map(bit => (
                <div key={bit} className="bit" style={generateRandomPosition()} />
            ))}
        </div>
    );
};

export default BitAnimation;
